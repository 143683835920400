import React, { useState } from "react";
import { AiOutlineTikTok, AiOutlineTwitter } from "react-icons/ai";
import {
    FaFacebookF,
    FaInstagram,
    FaLinkedinIn,
    // FaPhone,
    FaPinterest,
    FaWhatsapp,
    FaYoutube,
} from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { GoArrowRight } from "react-icons/go";
import { GrMail } from "react-icons/gr";
import { IoBriefcaseSharp } from "react-icons/io5";
import logo from '../../Assete/logo/Naskstudio (R).svg'
import { Link, useNavigate } from "react-router-dom";
import { FallBackImage } from "../TagsUse/data";
import SnakBar from "./SnakBar";

function Footer() {
    const navigate = useNavigate();
    //   const { newslater} = useAuth();
    const [value, setValue] = useState();
    const [errors, setError] = useState();
    const [show,setShow]=React.useState(false)
    const [message,setMessage]=React.useState({succes:false,message:""})
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    return (
        <div className="footer mt-5 ">
            <div className="d-flex justify-content-between flex-wrap flex-column flex-sm-row">
                <div className="footer_logo">
                    <Link to={'/'} className='text-decoration-none' style={{ color: "#494949" }}><FallBackImage src={logo} alt={"..."} fallbackSrc={logo} className={"logo"} style={{width:"174px"}}/></Link>
                </div>
                <div className="social_link">
                    <span>Follow Us</span>
                    <span>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/naskstudios"
                        >
                            <FaFacebookF />{" "}
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://twitter.com/NaskStudio"
                        >
                            <AiOutlineTwitter />{" "}
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/naskstudio_officials/"
                        >
                            <FaInstagram />{" "}
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/company/naskstudio/"
                        >
                            <FaLinkedinIn />{" "}
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.tiktok.com/@naskstudio"
                        >
                            <AiOutlineTikTok />{" "}
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="	https://www.youtube.com/@naskstudioofficial"
                        >
                            <FaYoutube />{" "}
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="	https://www.pinterest.co.uk/naskstudio/"
                        >
                            <FaPinterest />{" "}
                        </a>
                    </span>
                </div>
            </div>
            <hr style={{ borderColor: "gray", margin: "1rem 0" }} />
            <div className="row">
            <div className="col-lg-2 col-md-6 col-12">
                    <h5 className="text-center text-sm-start">Site Pages</h5>
                    <ul className="footerUL footerULNew">
                        {/* <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            Home
                        </li> */}
                        <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("/contact-us");
                            }}
                        >
                            Contact Us
                        </li>
                        {/* <li style={{cursor:"pointer"}} onClick={()=>{
                        navigate("/News")
                    }}>News</li> */}
                        <Link style={{ textDecoration: "none" }} to="/faqs">
                            <li
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    // navigate("/about-us")
                                }}
                            >
                                FAQ’s
                            </li>
                        </Link>
                        {/* <li style={{cursor:"pointer"}} onClick={()=>{
                        navigate("/Blogs")
                    }}>Blogs</li> */}
                        <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("/privacy-policy");
                            }}
                        >
                            Privacy Policy
                        </li>
                        <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("/term-and-condition");
                            }}
                        >
                            Terms & Conditions
                        </li>
                    </ul>
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                    <h5 className="text-center text-sm-start">Our Services</h5>
                    <ul className="footerUL footerULNew">
                      
                        <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("/props");
                            }}
                        >
                            Props
                        </li>
                        <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("/wedding-set-for-rent");
                            }}
                        >
                            Set For Wedding Shoot
                        </li>
                        <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("/studio-spaces");
                            }}
                        >
                            Rental Spaces
                        </li>
                        <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("/production-services");
                            }}
                        >
                            Production Services
                        </li>

                    </ul>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <h5 className="text-center text-sm-start">Contact Us</h5>
                    <ul className="footerUL ">
                        <li>
                            <span>
                                <FaWhatsapp className="me-2" />
                            </span>
                            <span>
                                0305-1115501
                            </span>

                        </li>
                        {/* <li>
                            <span>

                                <FaPhone className="me-2" />
                            </span>
                            <span>
                                0305-1115501

                            </span>
                        </li> */}
                        <li>
                            <span>

                                <GrMail className="me-2" />
                            </span>
                            <span>
                            sales@naskstudio.com
                            </span>
                        </li>
                        <li>
                            <span>

                                <IoBriefcaseSharp className="me-2" />
                            </span>
                            <span>
                                Mon-Sat 9:00 am to 10:00pm

                            </span>
                        </li>
                        <li className="mb-1">
                            <span>

                                <FaLocationDot className="me-2" />
                            </span>
                            <span>
                                Building No. 21, 3rd Floor,
                                Block-CCA, DHA Phase 8 - Ex Park View Phase-8 Lahore, 54000.

                            </span>
                        </li>
                    </ul>
                </div>

            
          
                <div className="col-lg-4 col-md-6 col-12 ">
                    <h6 className="text-center text-sm-start">Subscribe</h6>
                    <div style={{ position: "relative" }} className="footer_inp">
                        <input
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            type="email"
                            placeholder="Your E-mail"
                        />
                        <button
                            onClick={() => {
                                if (!value) {
                                    setError("Please enter email");
                                } else if (!isValidEmail(value)) {
                                    setError("Please enter a valid email");
                                } else {
                                    setTimeout(() => {
                                        setShow(true)
                                        setMessage({succes:true,message:"Email Sent Successfully",})
                                        //   newslater(value);
                                        setValue("");
                                        setError("");
                                        
                                    }, 1500);
                                }
                            }}
                        >
                            Send <GoArrowRight className="mx-2" />
                        </button>
                        {errors && (
                            <span style={{ top: "150%" }} className="error">
                                {errors}
                            </span>
                        )}
                    </div>
                    <hr className="d-none d-sm-block" />
                    <p style={{ color: "#646D6C" }} className="py-3 py-sm-0 mb-3">
                        Subscribe to our newsletter to receive our weekly feed.
                    </p>
                </div>
            </div>
            <hr style={{ borderColor: "gray", margin: "1rem" }} />
            <p className="copy_right">
                 All rights reserved by naskstudio.com
            </p>
            <SnakBar show={show} message={message}  setShow={setShow}/>
        </div>
    );
}

export default Footer;
