import React from 'react'
import { FallBackImage } from '../TagsUse/data'
import logo from '../../Assete/logo/Naskstudio (R).svg'
import { Link, useNavigate } from 'react-router-dom'
import { FaBarsStaggered } from 'react-icons/fa6'
import { IoMdClose } from 'react-icons/io'
function NavBar() {
    const [show, setShow] = React.useState(false)
    const navigate=useNavigate()
    return (
        <div style={{ borderBottom: "1px solid lightgray" }} className='nav-bar py-3'>
            <div className='row m-0'>
                <div className='col-md-12 col-7 d-flex justify-content-center  align-items-center p-0'>
                    <Link to={'/'} className='text-decoration-none' style={{ color: "#494949" }}><FallBackImage src={logo} alt={"..."} fallbackSrc={logo} className={"logo"} style={{width:"250px"}} /></Link>
                </div>
                <div className='col-md-12 col-5 d-flex justify-content-md-center justify-content-end align-items-center p-0 mt-2'>
                    <ul className='p-0 d-none d-md-flex h-100 m-0 align-items-center'>
                        <li className='my-1'><Link to={'/'} className='text-decoration-none' style={{color:`${window?.location?.pathname==="/"?"#483B8B":""}`}} >Home</Link></li>
                        <li className='my-1'><Link to={'/wedding-set-for-rent'} className='text-decoration-none' style={{color:`${window?.location?.pathname==="/wedding-set-for-rent"?"#483B8B":""}`}}>Set For Wedding Shoot</Link></li>
                        <li className='my-1'><Link to={'/studio-spaces'} className='text-decoration-none' style={{color:`${window?.location?.pathname==="/studio-spaces"?"#483B8B":""}`}}>Rental Spaces</Link></li>
                        <li className='my-1'><Link to={'/props'} className='text-decoration-none' style={{color:`${window?.location?.pathname==="/props"?"#483B8B":""}`}}>Props</Link></li>
                        <li className='my-1'><Link to={'/production-services'} className='text-decoration-none' style={{color:`${window?.location?.pathname==="/production-services"?"#483B8B":""}`}}>Production Services</Link></li>
                        <li className='my-1'><Link to={'/contact-us'} className='text-decoration-none' style={{color:`${window?.location?.pathname==="/contact-us"?"#483B8B":""}`}}>Contact Us</Link></li>
                    </ul>
                    <button onClick={()=>setShow(true)} className='d-md-none d-block btn'><FaBarsStaggered /></button>
                </div>
            </div>
            <div className={`side-bar ${show ? 'show' : ''}`}>
                <div className='row m-0 ps-1 me-3 ms-2 py-2'>
                    <div className='col-6 d-flex justify-content-start align-items-center p-0'>
                        <Link to={'/'} className='text-decoration-none' style={{ color: "#494949" }} ><FallBackImage src={logo} alt={"..."} fallbackSrc={logo} className={"logo w-100"} /></Link>
                    </div>
                    <div className='col-6 d-flex justify-content-end align-items-center'>
                    <button onClick={()=>setShow(false)} className='d-md-none d-block btn m-0 p-0'><IoMdClose  /></button>
                    </div>
                </div>
                <ul className='p-0 w-100 d-flex flex-column m-0 align-items-center me-5 mobile-nav'>
                    <li onClick={()=>navigate('/')} style={{background:`${window?.location?.pathname==="/"?"#483B8B":""}`}}><Link to={'/'}  className='text-decoration-none' style={{color:`${window?.location?.pathname==="/"?"white":"#494949"}` }}>Home</Link></li>
                    <li onClick={()=>navigate('/wedding-set-for-rent')} style={{background:`${window?.location?.pathname==="/wedding-set-for-rent"?"#483B8B":""}`}}><Link to={'/wedding-set-for-rent'} className='text-decoration-none' style={{color:`${window?.location?.pathname==="/wedding-set-for-rent"?"white":"#494949"}` }}>Set For Wedding Shoot</Link></li>
                    <li onClick={()=>navigate('/studio-spaces')} style={{background:`${window?.location?.pathname==="/studio-spaces"?"#483B8B":""}`}}><Link to={'/studio-spaces'} className='text-decoration-none' style={{color:`${window?.location?.pathname==="/studio-spaces"?"white":"#494949"}` }}>Rental Spaces</Link></li>
                    <li onClick={()=>navigate('/props')} style={{background:`${window?.location?.pathname==="/props"?"#483B8B":""}`}}><Link to={'/props'} className='text-decoration-none' style={{color:`${window?.location?.pathname==="/props"?"white":"#494949"}` }}>Props</Link></li>
                    <li onClick={()=>navigate('/production-services')} style={{background:`${window?.location?.pathname==="/production-services"?"#483B8B":""}`}}><Link to={'/production-services'} className='text-decoration-none' style={{color:`${window?.location?.pathname==="/production-services"?"white":"#494949"}` }}>Production Services</Link></li>
                    <li onClick={()=>navigate('/contact-us')} style={{background:`${window?.location?.pathname==="/contact-us"?"#483B8B":""}`}}><Link to={'/contact-us'} className='text-decoration-none' style={{color:`${window?.location?.pathname==="/contact-us"?"white":"#494949"}` }}>Contact</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default NavBar